document.addEventListener("DOMContentLoaded", function (event) {

    const boxesIcons = document.querySelectorAll('.js-boxes-slider-mobile');

    if (boxesIcons) {
        boxesIcons.forEach((boxes) => {
            if (boxes) {
                if (window.innerWidth <= 768) {
                    new Swiper(boxes, {
                        slidesPerView: 1.4,
                        slideToClickedSlide: true,
                        centeredSlides: true,
                        loop: true,
                        effect: 'slide',
                        speed: 2500,
                        pagination: false,
                        autoplay: {
                            delay: 2000
                        }
                    });
                }
            }
        });
    }

    //desktop slider 
    const slidersDesktop = document.querySelectorAll('.js-boxes-slider-desktop');

    if (slidersDesktop) {
        slidersDesktop.forEach((boxes) => {
            if (boxes) {
                new Swiper(boxes, {
                    slidesPerView: 2,
                    spaceBetween: 10,
                    slideToClickedSlide: true,
                    // centeredSlides: true,
                    loop: true,
                    effect: 'slide',
                    speed: 1500,
                    pagination: false,
                    autoplay: {
                        delay: 3000
                    },
                    navigation: {
                        nextEl: '.swiper-button-next-custom',
                        prevEl: '.swiper-button-prev-custom',
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 3,
                        },
                        992: {
                            slidesPerView: 4,
                        },
                    }
                });
            }
        });
    }
    //slider destkop icons


    //set the same height content 
    const parents = document.querySelectorAll('.fixed-height-el-js');

    const initHeight = (className, parent) => {

        let i = 0;

        const items = parent.querySelectorAll(className);

        const itemsHeight = [];

        for (i = 0; i < items.length; i++) {

            itemsHeight.push(items[i].offsetHeight);
        }

        const maxHeight = Math.max(...itemsHeight);

        for (i = 0; i < items.length; i++) {

            items[i].style.height = maxHeight + "px";

        }
    };

    if (parents) {
        parents.forEach((parent) => {
            if (window.innerWidth > 768) {
                initHeight('.c-box-icon__title', parent);
                initHeight('.c-box-icon__desc', parent);
                initHeight('.c-box-icon__footer', parent);
            }
        });
    };

});